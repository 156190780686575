/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

ion-header
{

  ion-toolbar
  {
    color : #ffffff;
    --background : #3f51b6;
  }
}
.genpad
{
  padding : 8px;
}
body
{
  --ion-background-color: #edeff1;
}

.mycard
{
  padding : 0.6em;
  padding-bottom: 0.2em;
  margin: 0.5em;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 3px 3px #b2b5bf;

}
ion-tab-bar
{
  --background: #e3e4e8;
}
